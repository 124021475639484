/* "background": "#e1e492",
"drip": "#3c2359", */

@use "sass:map";

body {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  background-color: #e1e492;
  box-sizing: border-box;
  border: 20px solid #3c2359;
  overflow: scroll;
  padding-bottom: 2rem;
}

.goosebump-font {
  font-family: 'Creepster', cursive;
}

h1 {
  font-family: 'Creepster', cursive;
  // font-size: 6rem;
  // line-height: 6rem;
  color: #3c2359;
  text-align: center;
  margin-bottom: 2rem;
  text-shadow: 2px 2px 3px rgb(0 0 0 / 25%);
  position: relative;
}

.tagline {
  color: #333;
  text-transform: uppercase;
}

.guesses {
  display: grid;
  grid-row-gap: 12px;
  margin: 0 auto;
}

.guess {
  width: 100%;
  border: solid 4px #000;
  padding: 12px;
  background: transparent;
  text-transform: uppercase;
  font-weight: 800;
  box-sizing: border-box;
  opacity: 0.5;
  /* font-family: 'Creepster', cursive; */
}

.guess.correct {
  opacity: 1;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.guess .rotate {
  /* transform: rotate(-90deg); */
}

.spine {
  border-left-width: 24px;
  @apply flex items-center gap-x-4 pl-8 shadow-md relative; 
  font-weight: bold; 

  .goosebump-font {
    font-size: 25px;
  }

  .icon {
    position: absolute;
    left: -17px;
  }
}

.selection {
  display: flex;
}

.book-select {
  flex-grow: 1;
}

.prompt {
  background: #3c2359;
}

.prompt input {
  width: 800px;
  background: transparent;
  padding: 12px 12px;
  margin: 12px auto;
  border: 0;
  border-bottom: solid 3px white;
  color: white;
  font-size: 18px;
}

.prompt input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 0.75;
}

.welcome {
  background: #b2d5cc;
  color: #725491;
}


.book-55 {
  background: #ecdeb7;
  color: #2b4d65;
  border-color: #2b4d65 !important;

  &.flipped {
    color: #ecdeb7;
    border-color: #ecdeb7 !important;
    background: #2b4d65;

    .icon {
      color: #2b4d65;
    }
  }
}
.book-18 {
  background: #f9b53e;
  color: #288547;
  border-color: #288547 !important;

  &.flipped {
    color: #f9b53e;
    border-color: #f9b53e !important;
    background: #288547;

    .icon {
      color: #288547;
    }
  }
}
.book-2 {
  background: #bfdf42;
  color: #502083;
  border-color: #502083 !important;

  &.flipped {
    color: #bfdf42;
    border-color: #bfdf42 !important;
    background: #502083;

    .icon {
      color: #502083;
    }
  }
}
.book-46 {
  background: #f87ea3;
  color: #15698e;
  border-color: #15698e !important;

  &.flipped {
    color: #f87ea3;
    border-color: #f87ea3 !important;
    background: #15698e;

    .icon {
      color: #15698e;
    }
  }
}
.book-25 {
  background: #fcfcfc;
  color: #f06425;
  border-color: #f06425 !important;

  &.flipped {
    color: #fcfcfc;
    border-color: #fcfcfc !important;
    background: #f06425;

    .icon {
      color: #f06425;
    }
  }
}
.book-54 {
  background: #d6f269;
  color: #a939a0;
  border-color: #a939a0 !important;

  &.flipped {
    color: #d6f269;
    border-color: #d6f269 !important;
    background: #a939a0;

    .icon {
      color: #a939a0;
    }
  }
}
.book-59 {
  background: #f0ca40;
  color: #82bf3d;
  border-color: #82bf3d !important;

  &.flipped {
    color: #f0ca40;
    border-color: #f0ca40 !important;
    background: #82bf3d;

    .icon {
      color: #82bf3d;
    }
  }
}
.book-23 {
  background: #c9cc3d;
  color: #61b9e1;
  border-color: #61b9e1 !important;

  &.flipped {
    color: #c9cc3d;
    border-color: #c9cc3d !important;
    background: #61b9e1;

    .icon {
      color: #61b9e1;
    }
  }
}
.book-9 {
  background: #a8dfe6;
  color: #7e62b6;
  border-color: #7e62b6 !important;

  &.flipped {
    color: #a8dfe6;
    border-color: #a8dfe6 !important;
    background: #7e62b6;

    .icon {
      color: #7e62b6;
    }
  }
}
.book-50 {
  background: #ec50a0;
  color: #555861;
  border-color: #555861 !important;

  &.flipped {
    color: #ec50a0;
    border-color: #ec50a0 !important;
    background: #555861;

    .icon {
      color: #555861;
    }
  }
}
.book-56 {
  background: #fbfcdd;
  color: #171741;
  border-color: #171741 !important;

  &.flipped {
    color: #fbfcdd;
    border-color: #fbfcdd !important;
    background: #171741;

    .icon {
      color: #171741;
    }
  }
}
.book-10 {
  background: #eaa761;
  color: #5d6012;
  border-color: #5d6012 !important;

  &.flipped {
    color: #eaa761;
    border-color: #eaa761 !important;
    background: #5d6012;

    .icon {
      color: #5d6012;
    }
  }
}
.book-37 {
  background: #f74e8c;
  color: #4c2851;
  border-color: #4c2851 !important;

  &.flipped {
    color: #f74e8c;
    border-color: #f74e8c !important;
    background: #4c2851;

    .icon {
      color: #4c2851;
    }
  }
}
.book-52 {
  background: #dad2dd;
  color: #9e481e;
  border-color: #9e481e !important;

  &.flipped {
    color: #dad2dd;
    border-color: #dad2dd !important;
    background: #9e481e;

    .icon {
      color: #9e481e;
    }
  }
}
.book-6 {
  background: #dbdbdd;
  color: #113c29;
  border-color: #113c29 !important;

  &.flipped {
    color: #dbdbdd;
    border-color: #dbdbdd !important;
    background: #113c29;

    .icon {
      color: #113c29;
    }
  }
}
.book-62 {
  background: #fc8aa3;
  color: #455bb1;
  border-color: #455bb1 !important;

  &.flipped {
    color: #fc8aa3;
    border-color: #fc8aa3 !important;
    background: #455bb1;

    .icon {
      color: #455bb1;
    }
  }
}
.book-45 {
  background: #cd4c24;
  color: #140c05;
  border-color: #140c05 !important;

  &.flipped {
    color: #cd4c24;
    border-color: #cd4c24 !important;
    background: #140c05;

    .icon {
      color: #140c05;
    }
  }
}
.book-13 {
  background: #79bebb;
  color: #4f1221;
  border-color: #4f1221 !important;

  &.flipped {
    color: #79bebb;
    border-color: #79bebb !important;
    background: #4f1221;

    .icon {
      color: #4f1221;
    }
  }
}
.book-38 {
  background: #88c2d5;
  color: #8e4f2e;
  border-color: #8e4f2e !important;

  &.flipped {
    color: #88c2d5;
    border-color: #88c2d5 !important;
    background: #8e4f2e;

    .icon {
      color: #8e4f2e;
    }
  }
}
.book-27 {
  background: #f7eb7e;
  color: #207ac5;
  border-color: #207ac5 !important;

  &.flipped {
    color: #f7eb7e;
    border-color: #f7eb7e !important;
    background: #207ac5;

    .icon {
      color: #207ac5;
    }
  }
}
.book-3 {
  background: #f3c01d;
  color: #1d399e;
  border-color: #1d399e !important;

  &.flipped {
    color: #f3c01d;
    border-color: #f3c01d !important;
    background: #1d399e;

    .icon {
      color: #1d399e;
    }
  }
}
.book-1 {
  background: #eee1df;
  color: #b00162;
  border-color: #b00162 !important;

  &.flipped {
    color: #eee1df;
    border-color: #eee1df !important;
    background: #b00162;

    .icon {
      color: #b00162;
    }
  }
}
.book-34 {
  background: #f6fa81;
  color: #eb602d;
  border-color: #eb602d !important;

  &.flipped {
    color: #f6fa81;
    border-color: #f6fa81 !important;
    background: #eb602d;

    .icon {
      color: #eb602d;
    }
  }
}
.book-49 {
  background: #eaa339;
  color: #282661;
  border-color: #282661 !important;

  &.flipped {
    color: #eaa339;
    border-color: #eaa339 !important;
    background: #282661;

    .icon {
      color: #282661;
    }
  }
}
.book-14 {
  background: #c7ea4a;
  color: #513a90;
  border-color: #513a90 !important;

  &.flipped {
    color: #c7ea4a;
    border-color: #c7ea4a !important;
    background: #513a90;

    .icon {
      color: #513a90;
    }
  }
}
.book-60 {
  background: #f8b86e;
  color: #264d89;
  border-color: #264d89 !important;

  &.flipped {
    color: #f8b86e;
    border-color: #f8b86e !important;
    background: #264d89;

    .icon {
      color: #264d89;
    }
  }
}
.book-57 {
  background: #f3ea8b;
  color: #e17812;
  border-color: #e17812 !important;

  &.flipped {
    color: #f3ea8b;
    border-color: #f3ea8b !important;
    background: #e17812;

    .icon {
      color: #e17812;
    }
  }
}
.book-40 {
  background: #eae2b4;
  color: #535e21;
  border-color: #535e21 !important;

  &.flipped {
    color: #eae2b4;
    border-color: #eae2b4 !important;
    background: #535e21;

    .icon {
      color: #535e21;
    }
  }
}
.book-5 {
  background: #d9c8db;
  color: #084960;
  border-color: #084960 !important;

  &.flipped {
    color: #d9c8db;
    border-color: #d9c8db !important;
    background: #084960;

    .icon {
      color: #084960;
    }
  }
}
.book-36 {
  background: #f99739;
  color: #224797;
  border-color: #224797 !important;

  &.flipped {
    color: #f99739;
    border-color: #f99739 !important;
    background: #224797;

    .icon {
      color: #224797;
    }
  }
}
.book-4 {
  background: #b5c2ca;
  color: #b21f1d;
  border-color: #b21f1d !important;

  &.flipped {
    color: #b5c2ca;
    border-color: #b5c2ca !important;
    background: #b21f1d;

    .icon {
      color: #b21f1d;
    }
  }
}
.book-51 {
  background: #aec0ee;
  color: #d91f10;
  border-color: #d91f10 !important;

  &.flipped {
    color: #aec0ee;
    border-color: #aec0ee !important;
    background: #d91f10;

    .icon {
      color: #d91f10;
    }
  }
}
.book-58 {
  background: #dcebe4;
  color: #b64187;
  border-color: #b64187 !important;

  &.flipped {
    color: #dcebe4;
    border-color: #dcebe4 !important;
    background: #b64187;

    .icon {
      color: #b64187;
    }
  }
}
.book-29 {
  background: #fae3c6;
  color: #0b271f;
  border-color: #0b271f !important;

  &.flipped {
    color: #fae3c6;
    border-color: #fae3c6 !important;
    background: #0b271f;

    .icon {
      color: #0b271f;
    }
  }
}
.book-15 {
  background: #94e4fe;
  color: #d9952b;
  border-color: #d9952b !important;

  &.flipped {
    color: #94e4fe;
    border-color: #94e4fe !important;
    background: #d9952b;

    .icon {
      color: #d9952b;
    }
  }
}
.book-47 {
  background: #d6eb5d;
  color: #833126;
  border-color: #833126 !important;

  &.flipped {
    color: #d6eb5d;
    border-color: #d6eb5d !important;
    background: #833126;

    .icon {
      color: #833126;
    }
  }
}
.book-16 {
  background: #bd8eb9;
  color: #2ea5af;
  border-color: #2ea5af !important;

  &.flipped {
    color: #bd8eb9;
    border-color: #bd8eb9 !important;
    background: #2ea5af;

    .icon {
      color: #2ea5af;
    }
  }
}
.book-42 {
  background: #f7ec04;
  color: #a51d3e;
  border-color: #a51d3e !important;

  &.flipped {
    color: #f7ec04;
    border-color: #f7ec04 !important;
    background: #a51d3e;

    .icon {
      color: #a51d3e;
    }
  }
}
.book-24 {
  background: #f495b5;
  color: #18112d;
  border-color: #18112d !important;

  &.flipped {
    color: #f495b5;
    border-color: #f495b5 !important;
    background: #18112d;

    .icon {
      color: #18112d;
    }
  }
}
.book-31 {
  background: #f789b3;
  color: #00bb51;
  border-color: #00bb51 !important;

  &.flipped {
    color: #f789b3;
    border-color: #f789b3 !important;
    background: #00bb51;

    .icon {
      color: #00bb51;
    }
  }
}
.book-33 {
  background: #abadc4;
  color: #229386;
  border-color: #229386 !important;

  &.flipped {
    color: #abadc4;
    border-color: #abadc4 !important;
    background: #229386;

    .icon {
      color: #229386;
    }
  }
}
.book-44 {
  background: #c8b8c5;
  color: #50a770;
  border-color: #50a770 !important;

  &.flipped {
    color: #c8b8c5;
    border-color: #c8b8c5 !important;
    background: #50a770;

    .icon {
      color: #50a770;
    }
  }
}
.book-35 {
  background: #fbe870;
  color: #96a69a;
  border-color: #96a69a !important;

  &.flipped {
    color: #fbe870;
    border-color: #fbe870 !important;
    background: #96a69a;

    .icon {
      color: #96a69a;
    }
  }
}
.book-48 {
  background: #fbfd79;
  color: #36ae35;
  border-color: #36ae35 !important;

  &.flipped {
    color: #fbfd79;
    border-color: #fbfd79 !important;
    background: #36ae35;

    .icon {
      color: #36ae35;
    }
  }
}
.book-61 {
  background: #fadb0f;
  color: #55155a;
  border-color: #55155a !important;

  &.flipped {
    color: #fadb0f;
    border-color: #fadb0f !important;
    background: #55155a;

    .icon {
      color: #55155a;
    }
  }
}
.book-11 {
  background: #fe9d1f;
  color: #047aa5;
  border-color: #047aa5 !important;

  &.flipped {
    color: #fe9d1f;
    border-color: #fe9d1f !important;
    background: #047aa5;

    .icon {
      color: #047aa5;
    }
  }
}
.book-22 {
  background: #d6d6b4;
  color: #558e85;
  border-color: #558e85 !important;

  &.flipped {
    color: #d6d6b4;
    border-color: #d6d6b4 !important;
    background: #558e85;

    .icon {
      color: #558e85;
    }
  }
}
.book-17 {
  background: #f9ed78;
  color: #f16729;
  border-color: #f16729 !important;

  &.flipped {
    color: #f9ed78;
    border-color: #f9ed78 !important;
    background: #f16729;

    .icon {
      color: #f16729;
    }
  }
}
.book-8 {
  background: #cde9dd;
  color: #6a5ab3;
  border-color: #6a5ab3 !important;

  &.flipped {
    color: #cde9dd;
    border-color: #cde9dd !important;
    background: #6a5ab3;

    .icon {
      color: #6a5ab3;
    }
  }
}
.book-19 {
  background: #fcdfab;
  color: #353b8f;
  border-color: #353b8f !important;

  &.flipped {
    color: #fcdfab;
    border-color: #fcdfab !important;
    background: #353b8f;

    .icon {
      color: #353b8f;
    }
  }
}
.book-30 {
  background: #d9d708;
  color: #651813;
  border-color: #651813 !important;

  &.flipped {
    color: #d9d708;
    border-color: #d9d708 !important;
    background: #651813;

    .icon {
      color: #651813;
    }
  }
}
.book-39 {
  background: #e7ec4a;
  color: #0c2b23;
  border-color: #0c2b23 !important;

  &.flipped {
    color: #e7ec4a;
    border-color: #e7ec4a !important;
    background: #0c2b23;

    .icon {
      color: #0c2b23;
    }
  }
}
.book-12 {
  background: #c2aad4;
  color: #911d85;
  border-color: #911d85 !important;

  &.flipped {
    color: #c2aad4;
    border-color: #c2aad4 !important;
    background: #911d85;

    .icon {
      color: #911d85;
    }
  }
}
.book-43 {
  background: #ced8e2;
  color: #2d1786;
  border-color: #2d1786 !important;

  &.flipped {
    color: #ced8e2;
    border-color: #ced8e2 !important;
    background: #2d1786;

    .icon {
      color: #2d1786;
    }
  }
}
.book-53 {
  background: #b3e8f7;
  color: #130941;
  border-color: #130941 !important;

  &.flipped {
    color: #b3e8f7;
    border-color: #b3e8f7 !important;
    background: #130941;

    .icon {
      color: #130941;
    }
  }
}
.book-7 {
  background: #d8d02d;
  color: #c51018;
  border-color: #c51018 !important;

  &.flipped {
    color: #d8d02d;
    border-color: #d8d02d !important;
    background: #c51018;

    .icon {
      color: #c51018;
    }
  }
}
.book-41 {
  background: #eae8e9;
  color: #c98f00;
  border-color: #c98f00 !important;

  &.flipped {
    color: #eae8e9;
    border-color: #eae8e9 !important;
    background: #c98f00;

    .icon {
      color: #c98f00;
    }
  }
}
.book-32 {
  background: #e5e6e8;
  color: #eb1e27;
  border-color: #eb1e27 !important;

  &.flipped {
    color: #e5e6e8;
    border-color: #e5e6e8 !important;
    background: #eb1e27;

    .icon {
      color: #eb1e27;
    }
  }
}
.book-21 {
  background: #d0e7cb;
  color: #983a73;
  border-color: #983a73 !important;

  &.flipped {
    color: #d0e7cb;
    border-color: #d0e7cb !important;
    background: #983a73;

    .icon {
      color: #983a73;
    }
  }
}
.book-28 {
  background: #efda4d;
  color: #16a2d6;
  border-color: #16a2d6 !important;

  &.flipped {
    color: #efda4d;
    border-color: #efda4d !important;
    background: #16a2d6;

    .icon {
      color: #16a2d6;
    }
  }
}
.book-20 {
  background: #e8ca3a;
  color: #972436;
  border-color: #972436 !important;

  &.flipped {
    color: #e8ca3a;
    border-color: #e8ca3a !important;
    background: #972436;

    .icon {
      color: #972436;
    }
  }
}
.book-26 {
  background: #87c8c0;
  color: #c61f32;
  border-color: #c61f32 !important;

  &.flipped {
    color: #87c8c0;
    border-color: #87c8c0 !important;
    background: #c61f32;

    .icon {
      color: #c61f32;
    }
  }
}
.book-0, .book-99 {
  background: #f2f2f2;
  color: #333;
  border-color: #333 !important;

  &.flipped {
    color: #f2f2f2;
    border-color: #f2f2f2 !important;
    background: #333;

    .icon {
      color: #333;
    }
  }
}


.drop {
  width: 0.1em;
  height: 0.1em;
  border-radius: 0 100% 100% 100%;
  background-color: currentColor;
  position: absolute;
  top: 72%;
  opacity: 1;
}

.drop-0 {
  animation: drop 4s infinite both; 
  width: 0.1em;
  height: 0.1em;
}
.drop-1 {
  animation: drop 3s infinite both; 
  width: 0.12em;
  height: 0.12em;
}
.drop-2 {
  animation: drop 2s infinite both; 
  width: 0.13em;
  height: 0.13em;
}
.drop-3 {
  animation: drop 1s infinite both; 
  width: 0.15em;
  height: 0.15em;
}
.drop-4 {
  animation: drop 0.5s infinite both; 
  width: 0.17em;
  height: 0.17em;
}

.drop:nth-child(1) {
  left: 13%;
}
.drop:nth-child(2) {
  left: 39%;
  animation-delay: -0.4s;
}
.drop:nth-child(3) {
  left: 55%;
  animation-delay: -1.5s;
}
.drop:nth-child(4) {
  left: 78%;
  animation-delay: -0.8s;
}
.drop:nth-child(5) {
  left: 95.5%;
  animation-delay: -1.3s;
}
@keyframes drop {
  0% {
    transform: translateY(0) scaleX(0.85) rotate(45deg);
    animation-timing-function: ease-out;
  }
  60% {
    transform: translateY(120%) scaleX(0.85) rotate(45deg);
    animation-timing-function: ease-in;
    opacity: 1;
  }
  80%, 100% {
    transform: translateY(75vh) scaleX(0.85) rotate(45deg);
    opacity: 0;
  }
}